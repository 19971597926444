
export const setUser = (user) => {
  let a = new Date();
  if (user.auth_type === 'google') {
    user.will_expire_at = a.setMinutes(a.getMinutes() + 60);
    localStorage.setItem('userDetails', JSON.stringify(user));
  } else {
    user.will_expire_at = a.setMinutes(a.getMinutes() + 600);
    //console.log(user.will_expire_at = a.setMinutes(a.getMinutes() + 720))
    localStorage.setItem('userDetails', JSON.stringify(user));
  }
};

export const getUser = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  //console.log(userDetails.will_expire_at)
  if (userDetails && new Date().getTime() < userDetails.will_expire_at) {
    return userDetails;
  }

  return {};
};

import React, { useState, useEffect, useMemo, useContext } from 'react';

// MUI
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import CardActionArea from '@material-ui/core/CardActionArea';
import { withStyles } from '@material-ui/core/styles';

// styles
import '../styles/About.css';

// Actions
import {
  getVersion,
  setMailer,
  getLogoLink,
  getVideoLinks,
} from '../../Actions/';

// Images
import labelsImage from '../../images/labels.png';

// Context
import { AppContext } from '../../Context/AppContextProvide';

const ImportExport = () => {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const tmpTitle = useMemo(() => [], []);
  const tmpId = useMemo(() => [], []);
  const tmpThumb = useMemo(() => [], []);
  const tmpDescription = useMemo(() => [], []);
  const [videoId, setVideoId] = useState([]);
  const [title, setTitle] = useState([]);
  const [thumbnail, setThumbnail] = useState([]);
  const [description, setDescription] = useState([]);
  const [selectedVidId, setSelectedVidId] = useState();
  const [videoTitle, setVideoTitle] = useState('Video Turorial');
  const [version, setVersion] = useState({});
  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [link, setLink] = useState();
  const to = 'support@showroompricing.com';
  const drawerWidth = 240;
  const styles = {
    tooltip: {
      maxWidth: '700px',
      height: '600px',
      borderRadius: '18px',
      boxShadow: '0 20px 80px 0',
      backgroundColor: '#28C4FC',
    },
  };
  const { user } = useContext(AppContext);
  const isAdmin = user.access_type_cd === 'a' ? true : false;
  const CustomTooltip = withStyles(styles)(Tooltip);
  const mailData = `<div style="background-color:#D4E7FA; padding:10px; border-radius:5px">
<div style="text-align: center;"><img src="${process.env.BASE_URL}/logo/logo.png" alt="Logo" height=50 width=150>
<br><p style="font-family:cursive; text-decoration: none !important; font-size:130%;">${link}</p></div><hr>
<h5>First Name: </h5>${fName}<h5> Last Name:</h5> <p>${lName}</p> <h5>Email: </h5><p>${email}</p>
  <h5>Phone: </h5><p>${phone} </p> <h5>Message: </h5> <p>${messageBody}</p></div>`;

  useEffect(() => {
    let abortController = new AbortController();
    getVideoLinks()
      .then((result) => {
        result.data.data.forEach((data) => {
          tmpId.push(data.video_id);
          tmpTitle.push(data.title);
          tmpDescription.push(data.description);
          tmpThumb.push(data.thumbnail);
        });
      })
      .then(() => {
        setVideoId(tmpId);
        setTitle(tmpTitle);
        setThumbnail(tmpThumb);
        setDescription(tmpDescription);
      });

    setEmail(JSON.parse(localStorage.getItem('userDetails')).email);
    getVersion()
      .then((result) => {
        setVersion(result.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getLogoLink()
      .then((result) => {
        setLink(result.data.data[0].logo_link);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      abortController.abort();
    };
  }, [drawerWidth, tmpDescription, tmpId, tmpThumb, tmpTitle]);

  const YoutubeEmbed = ({ embedId }) => (
    <div className="video-responsive">
      <iframe
        width="100%"
        height="100%"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Tutorial"
      />
    </div>
  );

  const resetVars = () => {
    setFName('');
    setLName('');
    setEmail('');
    setPhone('');
    setMessageBody('');
  };

  const handleOpen = (vId, title) => {
    setSelectedVidId(vId);
    setVideoTitle(title);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAlertOpen = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleFname = (e) => {
    setFName(e);
  };

  const handleLname = (e) => {
    setLName(e);
  };

  const handleEmail = (e) => {
    setEmail(e);
  };

  const handlePhone = (e) => {
    setPhone(e);
  };

  const handleMessageBody = (e) => {
    setMessageBody(e);
  };

  const handleSubmit = () => {
    setShowProgress(true);
    setMailer(
      to,
      fName,
      lName,
      email,
      phone,
      'Support request',
      messageBody,
      link,
      mailData
    )
      .then((result) => {
        resetVars();
        handleAlertOpen(true);
        setShowProgress(false);
      })
      .then((err) => {
        console.log(err);
      });
  };

  const alert = () => {
    return (
      <Dialog
        open={alertOpen}
        onClose={handleAlertClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Message Sent!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Thank you! We will get back to you soon.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAlertClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const VideoCardData = ({ videoId, thumbnail, description, title }) => {
    return (
      <Card elevation={10} className="card">
        <CardActionArea onClick={() => handleOpen(videoId, title)}>
          <CardMedia
            component="img"
            title="Instructional Videos"
            image={thumbnail}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {title}
            </Typography>
            <Typography variant="body2">{description}</Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button href="#about-tab-paper">Back to top</Button>
        </CardActions>
      </Card>
    );
  };

  const renderContactForm = () => {
    return (
      <div>
        {showProgress && <CircularProgress size={50} />}
        <h1>Send us a support request.</h1>

        <TextField
          margin="dense"
          id="fName"
          label="First Name"
          type="text"
          variant="filled"
          onChange={(e) => handleFname(e.target.value)}
          value={fName}
          className="text-field"
        />

        <TextField
          margin="dense"
          id="lName"
          label="Last Name"
          type="text"
          variant="filled"
          onChange={(e) => handleLname(e.target.value)}
          value={lName}
          className="text-field"
        />

        <TextField
          margin="dense"
          id="name"
          label="Email Address"
          type="email"
          variant="filled"
          required
          onChange={(e) => handleEmail(e.target.value)}
          value={email}
          className="text-field"
        />

        <TextField
          margin="dense"
          id="phone"
          label="Phone Number"
          type="number"
          variant="filled"
          onChange={(e) => handlePhone(e.target.value)}
          value={phone}
          className="text-field"
        />

        <TextField
          className="message-text-field"
          margin="dense"
          id="body"
          label="message"
          variant="filled"
          multiline
          rows={10}
          onChange={(e) => handleMessageBody(e.target.value)}
          value={messageBody}
        />
        <Button
          className="contact-button"
          onClick={handleSubmit}
          variant={'contained'}
        >
          Submit
        </Button>
      </div>
    );
  };

  const renderChangeNotes = () => {
    return (
      <div style={{ width: 600 }}>
        <Typography variant="button">
          <h2>CHANGELOG</h2>

          <h3>New Features you will find in the release:</h3>

          <ul>
            <li>
              View by day metrics now customizable by role, user type, and
              material class.
            </li>
            <li>Ability to show manufacturer on product page.</li>
            <li>Retail price override by product on product search page.</li>
            <li>Abiltiy to add customer sales tax.</li>
            <li>More detailed descriptions on QR codes.</li>
            <li>Show last modified date on product search page.</li>
            <li>
              Ability to add and show products with multiple distributors.
            </li>
            <li>
              Added additional search fields and an advanced seach section.
            </li>
            <li>Added additional search fields to manual upload template.</li>
            <li>Ability to add product disclaimner based on material class.</li>
            <li>View price by role on product page.</li>
            <li>Added locations to QR codes.</li>
            <li>Show last date user logged in.</li>
            <li>Added the ability to export csv files on file export page.</li>
          </ul>

          <h3>Changes you will find in the release:</h3>

          <ul>
            <li>New look with better contrast and uniform layouts.</li>
          </ul>
        </Typography>
      </div>
    );
  };

  return (
    <div className="container">
      <Paper className="about-tab-paper" id="about-tab-paper">
        <div className="about-tab-container">
          <div className="tab-links">
            <a href="#video-container" className="tab-links">
              <p className="tab-links">INSTRUCTIONAL VIDEOS</p>
            </a>
          </div>
          {isAdmin && (
            <div className="tab-links">
              <a href="#contact" className="tab-links">
                <p className="tab-links">TECHNICAL SUPPORT</p>
              </a>
            </div>
          )}
          <div className="tab-links">
            <a href="#labels" className="tab-links">
              <p className="tab-links">ORDER LABELS</p>
            </a>
          </div>
        </div>

        {/* Mobile Tabs */}

        <div className="about-tab-container-mobile">
          <div className="tab-links">
            <a href="#about" className="tab-links">
              <p className="tab-links">ABOUT</p>
            </a>
          </div>
          {isAdmin && (
            <div className="tab-links">
              <a href="#contact" className="tab-links">
                <p className="tab-links">CONTACT</p>
              </a>
            </div>
          )}
          <div className="tab-links">
            <a href="#video-container" className="tab-links">
              <p className="tab-links">INSTRUCTIONAL VIDEOS</p>
            </a>
          </div>
          <div className="tab-links">
            <a href="#labels" className="tab-links">
              <p className="tab-links">ORDER LABELS</p>
            </a>
          </div>
        </div>
      </Paper>
      {/* <div className="about-container"><h1 style={{marginTop:'5%'}}>Instructional Videos</h1></div> */}
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="lg"
        className="video-popup"
      >
        <DialogTitle className="video-dialog">
          <Typography variant={'h5'}>{videoTitle}</Typography>
        </DialogTitle>
        <DialogContent>
          <YoutubeEmbed embedId={selectedVidId} />
        </DialogContent>
      </Dialog>
      <div className="video-container">
        <Typography variant="h4" className="about__header">
          Instructional Videos
        </Typography>
        <Paper className="video-grid-paper" elevation={3}>
          <Grid container spacing={5} className="video-grid">
            <Grid item xs={12}>
              {/* <Typography
                  variant="h4"
                  className="about__header"
                >
                  Instructional Videos
                </Typography> */}
            </Grid>
            <Grid item xs={12}>
              <p style={{ fontSize: '25px' }}>
                Here you will find a number of helpful videos to guide you
                through the software. You can also visit our <a href="http://www.youtube.com/@showroompricing1866" target="_blank">youtube page</a> to see more.
              </p>
            </Grid>
            {thumbnail.map((thumb, index) => {
              return (
                <Grid item xs={12} sm={9} md={6} lg={3} key={index}>
                  <VideoCardData
                    videoId={videoId[index]}
                    thumbnail={thumbnail[index]}
                    description={description[index]}
                    title={title[index]}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Paper>
      </div>
      <Divider />
      <div className="about-container">
        {isAdmin && (
          <div>
            <h1 style={{ marginTop: '5%' }}>Technical Support</h1>
            <p className="about__support-text">
              For support or any other questions please contact us at{' '}
              <a href="tel:314-530-9996" className="links">
                314-530-9996{' '}
              </a>
              or use the contact form below.
            </p>
            <br />
            <p className="support-hours">(Mon-Fri 9AM-5PM CST)</p>
            <br />
            <br id="contact" />
            <Paper className="contact-form" elevation={5}>
              <div>
                <Typography variant="h4" className="about__constact-header">
                  Contact Us
                </Typography>
              </div>
              {renderContactForm()}
            </Paper>
          </div>
        )}
        {/* <Button
          className="contact-button"
          variant="contained"
          onClick={handleContactFormOpen}
        >
          Contact Us
        </Button> */}
        <a href="#about-tab-paper" className="to-top-links">
          <p className="about__back-to-top-footer">BACK TO TOP</p>
        </a>
        <br />
        <Divider />
      </div>
      <div className="about-container">
        <h1 style={{ marginTop: '5%' }}>
          Click the link below to purchase labels
        </h1>
        <a
          id="labels"
          target="blank"
          href="https://www.onlinelabels.com/uses/showroompricing?reward=FRL2lTUaMKk%3D"
        >
          <img
            style={{ width: '75%', border: 'solid', borderColor: '#4E564E' }}
            src={labelsImage}
            alt={'Label link'}
          />
        </a>
      </div>
      <Divider />
      {/* <CustomTooltip title={renderChangeNotes()}> */}
      <div className="about-container">
        <Typography variant="h4" className="about__version">
          Versions
        </Typography>
        <h5>API Version: {version.version}</h5>
        <h5>APP Version: {version.app_version}</h5>
        <h5>DB Version: {version.db}</h5>
      </div>
      {/* </CustomTooltip> */}
      <Divider />
      {alert()}
    </div>
  );
};

export default ImportExport;
